import React from "react";
import { useToggle } from "react-use";
import Block from "./Block";
import { ReactComponent as ApplauseIcon } from "../svgs/applause.svg";
import { ReactComponent as ThinkingIcon } from "../svgs/thinking.svg";
import { ReactComponent as InformationIcon } from "../svgs/information.svg";
import { ReactComponent as GroupIcon } from "../svgs/ressources-humaines.svg";
import RecipientsList from "./RecipientsList";
import {get} from "lodash-es";
import Avatar from "./Avatar";
import {getFirstnameLastname} from "../utils/names";

const styles = {
  default: {
    block: "p-4",
    children: "p-4",
    topDiv: "bg-white shadow mb-4 xl:mb-3",
  },
  home: {
    block: "shadow p-4 bg-white",
    children: "",
    topDiv: "mb-4",
  },
};

function getRecipientListAsString(recipients) {
  let recipientNumber = 0;
  let listRecipients = "";

  recipients.forEach(recipient => {
    if (recipientNumber < 2) {
      if (recipientNumber > 0) {
        listRecipients += ", ";
      }

      listRecipients += getFirstnameLastname(recipient, "collaborator");
    }
    recipientNumber++;
  })

  if (recipientNumber > 2) {
    listRecipients += ', ...';
  }

  return listRecipients;
}

function MessagePanel({
  children,
  handleClick,
  forceOpen = false,
  open = false,
  className,
  customStyle = "default",
  message,
  isCopy = false,
  send = false,
  isValidated = true,
}) {
  const [on, toggle] = useToggle(open);
  const style = styles[customStyle];
  const [showRecipients, setShowRecipients] = useToggle(false);
  const onlyOneRecipient = send && message?.recipients?.length === 1;

  //On affiche le nom de l'auteur si c'est un message reçu ou les destinataires si c'est un message envoyé
  let headerName = !send
      ? message.author.collaborator
          ? getFirstnameLastname(message, "author.collaborator") : get(message, "author.username")
      : getRecipientListAsString(get(message, "recipients", []))
  ;

  let avatar = message.author.collaborator?.avatar?.contentUrl;

  if (onlyOneRecipient) {
    const collaborator = get(message, "recipients.0.collaborator");
    avatar = get(collaborator, "avatar.contentUrl");
  }

  return (
    <div className={style["topDiv"]}>
      <Block
        className={`${handleClick ? "hover:shadow-md" : ""} ${className}`}
        customStyle={style["block"]}
      >
        <div
          className={`flex items-center cursor-pointer border-b border-gray-100 pb-2`}
          onClick={() => {
            if (handleClick) {
              handleClick();
            }
            toggle();
          }}
        >
          <div className="w-full flex justify-between">
            <div className="flex gap-6 items-center">
              <div className="w-12 h-12">
                {send && !onlyOneRecipient ? (
                  <div className="rounded-full w-full h-full bg-purple-600 flex items-center justify-center">
                    <GroupIcon className="text-white w-6 h-6 fill-current" />
                  </div>
                ) : (
                  <Avatar avatar={avatar} />
                )}
              </div>
              <div>
                {!isValidated && (
                    <span className="bg-orange-400 text-white text-xs px-2 py-1 rounded-full tracking-wide">
                      En attente de validation
                    </span>
                )}
                <div className="relative flex align-center">
                  <span className="font-bold text-lg">{headerName}</span>
                  {isCopy && (
                    <span className="bg-purple-500 text-white text-xs px-2 py-1 ml-2 rounded-full tracking-wide">
                      En copie
                    </span>
                  )}
                  {get(message, "recipients", false) ? (
                    <>
                      <div
                        className="p-1 ml-2 hover:bg-gray-100 inline-flex rounded-sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowRecipients();
                        }}
                      >
                        <span className="triangle bg-green-600" />
                      </div>

                      <RecipientsList
                        showRecipients={showRecipients}
                        recipients={get(message, "recipients", false)}
                        copyRecipients={get(message, "copyRecipients", false)}
                      />
                    </>
                  ) : null}
                </div>
                <div className="text-gray-400">
                  {get(message, "sendDate")
                ? new Date(get(message, "sendDate")).toLocaleDateString()
                : new Date(get(message, "createdAt")).toLocaleDateString()}
                </div>
                <div className="text-lg">{get(message, "type.label")}</div>
              </div>
            </div>

            <div className="mr-8">
              {message.information || get(message, "type.noPositivity", false) ? (
                  <InformationIcon className="text-blue-400 w-5 h-5 fill-current" />
              ) : message.positive === true ? (
                <ApplauseIcon className="text-green-600 w-5 h-5 fill-current" />
              ) : message.positive === false ? (
                <ThinkingIcon className="text-purple-400 w-5 h-5 fill-current" />
              ) : null}
            </div>
          </div>
          {children && !forceOpen ? (
            <div className="ml-auto">
              <svg
                className={`transform fill-current text-green-600 ${
                  on ? "rotate-180" : ""
                }`}
                width="20"
                height="12"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.667 0L10 7.46 2.333 0 0 2.27 10 12l10-9.73L17.667 0z"
                />
              </svg>
            </div>
          ) : null}
        </div>
      </Block>
      {children ? (
        <div className={`${forceOpen || on ? "block" : "hidden"}`}>
          <div className={style["children"]}>{children}</div>
        </div>
      ) : null}
    </div>
  );
}

export default MessagePanel;
