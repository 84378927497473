import { useFormikContext } from "formik";
import { get, isEmpty, map } from "lodash-es";
import DateFormat from "../components/DateFormat";
import { FAMILY_SITUATIONS } from "../graphql";
import Input, { DateTimePickerInput } from "../components/Input";
import React from "react";
import Select from "../components/Select";
import { useQuery } from "@apollo/react-hooks";
import { usePermissionsChecker } from "../contexts/permissions";
import Button from "../components/Button";

function FamilySituation({ personalInformations }) {
  const readOnly = !usePermissionsChecker({
    permissions: [
      "kdix.actions.personal_information.edit",
      "kdix.actions.personal_information.edit.agency",
      "kdix.actions.personal_information.edit.department",
      "kdix.actions.personal_information.edit.service",
      "kdix.actions.personal_information.edit.own",
    ],
  });
  const familySituations = useQuery(FAMILY_SITUATIONS);
  const { values, setFieldValue } = useFormikContext();
  return (
    <>
      {get(personalInformations, "dateFamilySituation") ? (
        <div className="mb-5">
          au :{" "}
          <span className="font-bold">
            <DateFormat
              date={get(personalInformations, "dateFamilySituation")}
            />
          </span>
        </div>
      ) : null}
      {familySituations ? (
        <Select
          label="Situation Familiale"
          name="familySituation"
          options={map(
            get(familySituations, "data.familySituations.edges"),
            ({ node }) => ({
              label: node.label,
              value: node.id,
            })
          )}
          value={values.familySituation}
          isDisabled={readOnly}
          isLoading={!familySituations.data}
        />
      ) : null}

      <Input
        type="text"
        name="partnerFirstName"
        label="Prénom du conjoint"
        readOnly={readOnly}
      />
      <Input
        type="text"
        name="partnerLastName"
        label="Nom du conjoint"
        readOnly={readOnly}
      />
      <DateTimePickerInput
        disabled={readOnly}
        name="partnerBirthDate"
        label="Anniversaire du conjoint"
      />

      <div className="mt-12">
        <div className="flex justify-between">
          <div className="font-bold text-lg leading-tight relative">
            Enfants:
          </div>

          {!readOnly && (
            <Button
              className="btn btn--sm"
              type="button"
              onClick={() => {
                setFieldValue("children", [
                  {
                    firstname: "",
                    lastname: "",
                    gender: "",
                    birthDate: "",
                  },
                  ...values.children,
                ]);
              }}
            >
              Ajouter un enfant
            </Button>
          )}
        </div>
        {isEmpty(values.children) && (
          <div className="mt-5">
            <p>Aucun enfant</p>
          </div>
        )}
        <div className="grid md:grid-cols-2  -mx-8 pb-8">
          {map(values.children, (child, index) => (
            <div key={index} className="px-8 my-8">
              <Input name={`children[${index}].firstname`} label="Prénom" />
              <Input name={`children[${index}].lastname`} label="Nom" />
              <Input name={`children[${index}].gender`} label="Genre" />
              <DateTimePickerInput
                name={`children[${index}].birthDate`}
                label="Date de naissance"
              />
              <div className={"flex"}>
                <div
                  className="btn btn--sm btn--error ml-auto"
                  onClick={() => {
                    setFieldValue(
                      "children",
                      values.children.filter((c, idx) => index !== idx)
                    );
                  }}
                >
                  Supprimer
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default FamilySituation;
