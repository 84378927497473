import React, { useState } from "react";
import { flatMap, get, map, pull, size } from "lodash-es";
import { useDebounce, useMap, useToggle } from "react-use";
import Alert from "../../components/Alert";
import ExpansionPanel from "../../components/ExpansionPanel";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroller";
import { Link } from "react-router-dom";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";
import { useDesktop } from "../../hooks/useDesktop";
import DeleteAccreditation from "../../forms/DeleteAccreditation";
import { useQueryAffectedAccreditationsInfinite } from "../../api/affectedAccreditation";
import { useQueryAgencies } from "../../api/agencies";
import { downloadAccreditationCertificate } from "../../api/accreditationCertificate";
import { ReactComponent as FileIcon } from "../../svgs/file.svg";
import { ReactComponent as FilterIcon } from "../../svgs/filters.svg";
import CheckboxGroup from "../../components/CheckboxGroup";
import { useQueryJobs } from "../../api/jobs";
import { CreateAccreditationCollaboratorForm } from "../../forms/AccreditationCollaboratorForm";
import { usePermissionsChecker } from "../../contexts/permissions";
import {getFirstnameLastname, getFirstnameLastnameJob} from "../../utils/names";
import { HeaderBar } from "../../components/HeaderBar";
import {CSVLink} from "react-csv";
import InlineBLockContentSortable from "../../components/InlineBlockContentSortable";
import Button from "../../components/Button";



function InlineBLockContent({ children, className = "" }) {
  return (
    <div className={`px-4 flex items-center ${className}`}>{children}</div>
  );
}

function AffectedAccreditationMobileCard({ affectedAccreditation }) {
  return (
    <ExpansionPanel
      key={affectedAccreditation.id}
      title={getFirstnameLastnameJob(affectedAccreditation, "collaborator")}
      subtitle={get(affectedAccreditation, "accreditation.label")}
    >
      <CreateAccreditationCollaboratorForm
        affectedAccreditation={affectedAccreditation}
        readOnly
      />
      <Link
        className="btn mt-6 w-full block text-center"
        to={`/rh/habilitations/habilite/${affectedAccreditation.id}`}
      >
        Modifier
      </Link>
    </ExpansionPanel>
  );
}

function AccreditationsList({ query, filters }) {
  const isDesktop = useDesktop();
  const [orderFilter, setOrderFilter] = useState({
    "order[dateExpiration]": "desc",
  });
  const {
    data,
    fetchMore,
    canFetchMore,
  } = useQueryAffectedAccreditationsInfinite({
    pagination: false,
    orSearch_fts_collaborator_accreditation: query,
    ...filters,
    ...orderFilter,
  });

  const [modalDeleteAccreditation, setModalDeleteAccreditation] = useState(
    false
  );

  const permissionsDelete = [
    "kdix.actions.accreditation.manage",
    "kdix.actions.accreditation.manage.agency",
    "kdix.actions.accreditation.manage.department",
    "kdix.actions.accreditation.manage.service",
    "kdix.actions.accreditation.manage.own",
  ];

  const hasPermissionsDelete = usePermissionsChecker({
    permissions: permissionsDelete,
  });
  if (size(data) <= 0) {
    return (
      <div className="mt-12">
        <Alert type="warning" message="aucune habilitation" />
      </div>
    );
  }
  const AffectedAccreditations = flatMap(data, (page) => page["hydra:member"]);
  const gridTemplateColumn = `16% 16% 12% 28% 10% 10% 8%`;

  const csvHeaders = [
    {label: "Collaborateur", key: "collaboratorFullName"},
    {label: "Emploi", key: "collaborator.currentContract.job.label"},
    {label: "Site", key: "authorAgencies"},
    {label: "Habilitation", key: "accreditation.label"},
    {label: "Description", key: "accreditation.description"},
    {label: "Date d'obtention", key: "dateObtainedFr"},
    {label: "Date d'expiration", key: "dateExpirationFr"},
  ];
  


  const csvData = map(AffectedAccreditations, (item) => {
    const authorAgencies = map(item.collaborator.agencies, (agency) => (agency.label)).sort();
    return {
      ...item,
      dateObtainedFr: item.dateObtained
          ? new Date(item.dateObtained).toLocaleDateString()
          : "",
      dateExpirationFr: item.dateExpiration
          ? new Date(item.dateExpiration).toLocaleDateString()
          : "",
      collaboratorFullName: getFirstnameLastname(item, "collaborator"),
      authorAgencies: authorAgencies.join('/'),
    };
  });

  return (
      <div className="relative">
        <div className="absolute -top-20 py-4 right-0">
          <CSVLink
              filename="habilités.csv"
              className="btn-export"
              headers={csvHeaders}
              data={csvData}
          >
            Exporter
          </CSVLink>
        </div>
      {isDesktop && (
        <div
          className={`grid divide-x text-white divide-white bg-gray-500 shadow mb-2 py-2 sticky top-tab-header always-front`}
          style={{
            gridTemplateColumns: gridTemplateColumn,
          }}
        >
          <InlineBLockContentSortable
            colName={"collaborator.lastname"}
            setOrderFilter={setOrderFilter}
          >
          Collaborateur
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
            setOrderFilter={setOrderFilter}
            colName={"collaborator.agencies.label"}
          >
          Site(s)
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
            colName={"accreditation.label"}
            setOrderFilter={setOrderFilter}
          >
          Habilitation
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
            colName={"accreditation.description"}
            setOrderFilter={setOrderFilter}
          >
          Description
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
            colName={"dateObtained"}
            setOrderFilter={setOrderFilter}
          >
          Date d'obtention
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
            colName={"dateExpiration"}
            setOrderFilter={setOrderFilter}
          >
          Date d'expiration
          </InlineBLockContentSortable>
          <InlineBLockContent className="justify-center">
            Justificatif
          </InlineBLockContent>
        </div>
      )}
      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={() => {
          fetchMore();
        }}
        hasMore={canFetchMore !== false}
        loader={
          <div key={0} className=" relative">
            <Spinner />
          </div>
        }
      >
        {isDesktop
          ? map(AffectedAccreditations, (node) => (
              <SingleLine
                node={node}
                gridTemplateColumn={gridTemplateColumn}
                permissionsDelete={permissionsDelete}
                setModalDeleteAccreditation={setModalDeleteAccreditation}
                hasPermissionsDelete={hasPermissionsDelete}
              />
            ))
          : map(AffectedAccreditations, (node, index) => (
              <AffectedAccreditationMobileCard
                key={node.id}
                affectedAccreditation={node}
              />
            ))}
      </InfiniteScroll>

      <Modal
        title="Supprimer une habilitation"
        handleClose={(e) => {
          e.stopPropagation();
        }}
        isOpen={modalDeleteAccreditation !== false}
        onRequestClose={() => setModalDeleteAccreditation(false)}
      >
        <div className="bg-white">
          <DeleteAccreditation
            id={modalDeleteAccreditation}
            onSuccess={() => setModalDeleteAccreditation(false)}
          />
        </div>
      </Modal>
    </div>
  );
}

function SingleLine({
  node,
  permissionsDelete,
  gridTemplateColumn,
  setModalDeleteAccreditation,
  hasPermissionsDelete,
}) {
  return (
    <Link
      to={`/rh/habilitations/habilite/${node.id}`}
      key={node.id}
      className={`grid divide-x divide-gray-100 bg-white hover:bg-purple-50 shadow mb-2 py-2`}
      style={{
        gridTemplateColumns: gridTemplateColumn,
      }}
    >
      <InlineBLockContent>
        {get(node, "collaborator.lastname")}{" "}
        {get(node, "collaborator.firstname")}{" - "}
        {get(node, "collaborator.currentContract.job.label")}
      </InlineBLockContent>
      <InlineBLockContent>
        {map(get(node, "collaborator.agencies"), (agency, key) => (
            <div key={key} className="mr-2">
              {agency.label.toLowerCase()}
            </div>
          ))}
      </InlineBLockContent>
      <InlineBLockContent>
        {get(node, "accreditation.label")}
      </InlineBLockContent>
      <InlineBLockContent>
        {get(node, "accreditation.description")}
      </InlineBLockContent>
      <InlineBLockContent>
        {node.dateObtained
          ? new Date(node.dateObtained).toLocaleDateString()
          : ""}
      </InlineBLockContent>
      <InlineBLockContent>
        {node.dateExpiration
          ? new Date(node.dateExpiration).toLocaleDateString()
          : ""}
      </InlineBLockContent>
      <InlineBLockContent>
        {get(node, "certificate", false) ? (
          <div
            className="w-full flex items-center justify-center hover:text-green-600 text-black"
            onClick={(e) => {
              e.preventDefault();
              if (get(node, "certificate.id")) {
                downloadAccreditationCertificate(
                  get(node, "certificate.id"),
                  get(node, "certificate.label")
                );
              }
            }}
          >
            <FileIcon className="cursor-pointer fill-current h-6 w-6" />
          </div>
        ) : (
          <div className="w-full flex items-center justify-center">
            <FileIcon className=" fill-current text-gray-100 h-6 w-6" />
          </div>
        )}
      </InlineBLockContent>
    </Link>
  );
}

function AffectedAccreditations() {
  const now = new Date();
  now.setHours(0, 0, 0, 0);

  const [showAll, toggleShowAll] = useToggle(false);
  const [showAfterNow, toggleShowAfterNow] = useToggle(true);
  const isDesktop = useDesktop();
  
  const [
    filters,
    { set: setFilter, remove: removeFilter, reset: resetFilters },
  ] = useMap({
    "collaborator.active": [1],
  });

  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  
  const [,] = useDebounce(
    () => {
      setDebouncedQuery(query);
    },
    500,
    [query]
  );
  const [FiltersOpen, toggleFilters] = useToggle(false);
  const { data: jobs } = useQueryJobs();
  const { data: agencies } = useQueryAgencies();
  const filtersList = [
    {
      label: "Collaborateur",
      key: "collaborator.active",
      options: [
        {
          label: "Anciens collaborateurs",
          value: 0,
          id: 0,
        },
        {
          label: "Collaborateurs actifs",
          value: 1,
          id: 1,
        },
      ],
    },
    {
      label: "Emploi",
      key: "collaborator.currentContract.job",
      options: map(jobs, (node) => ({
        label: node.label,
        value: node["@id"],
        id: node["@id"],
      })),
    },
    {
       label: "Site" ,
       key: "agencies",
       options: map(agencies, (node) => ({
        label: node.label,
        value: node["@id"],
        id: node["@id"],
      })),
    },
  ];

  React.useEffect(() => {
            var _paq = window._paq = window._paq || [];
            _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
       
            var u="//matomo.kdix.pockost.com/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '1']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    }, [])
  
  return (
    <div>
      <Helmet>
        <title>Liste des habilités</title>
      </Helmet>
      <HeaderBar
        sticky={true} smaller={true}
        title={
          <>
            Liste des habilités
          </>
        }
      >
        <input
          type="text"
          name="query"
          value={query}
          className="mb-0 appearance-none bg-transparent border-b border-white w-full py-2  leading-tight focus:outline-none focus:border-red"
          placeholder="Rechercher une habilitation"
          onChange={(e) => setQuery(e.target.value)}
        />
      </HeaderBar>

      <div>
        <div className="px-8 mt-8 mb-4 flex">
          {showAll ? (
            <button
              className="flex items-center"
              onClick={() => toggleFilters(true)}
            >
              <FilterIcon />
              <span className="ml-4">Filtres</span>
            </button>
          ) : null}
          <Button
              className={`p-1 px-2 btn-export ${showAll ? 'btn--outline' : 'btn--outline--reversed'} mx-1 my-1`}
              onClick={() => {
                resetFilters();
                toggleShowAll(true);
              }}
          >
            Tout
          </Button>
          <Button
              className={`p-1 px-2 btn-export ${!showAll && showAfterNow ? 'btn--outline' : 'btn--outline--reversed'} mx-1 my-1`}
              onClick={() => {
                toggleShowAll(false);
                toggleShowAfterNow(true);
                resetFilters();
                setFilter('dateExpiration[after]', now);
              }}
          >
            {isDesktop ? "Habilit. en cours" : "En cours"}
          </Button>
          <Button
              className={`p-1 px-2 btn-export ${!showAll && !showAfterNow ? 'btn--outline' : 'btn--outline--reversed'} mx-1 my-1`}
              onClick={() => {
                toggleShowAll(false);
                toggleShowAfterNow(false);
                resetFilters();
                setFilter('dateExpiration[before]', now);
              }}
          >
            {isDesktop ? "Anciennes habilit." : "Expirées"}
          </Button>
          <Modal
            title="Filtres"
            handleClose={(e) => {
              e.stopPropagation();
              toggleFilters(false);
            }}
            className="outline-none w-full max-w-full lg:max-w-xl max-h-full overflow-visible overflow-y-auto"
            isOpen={FiltersOpen}
            onRequestClose={() => toggleFilters(false)}
          >
            <div className="-mx-4 px-5">
              {map(filtersList, ({ key, label, options }) => {
                return (
                  <CheckboxGroup
                    key={key}
                    label={label}
                    options={options}
                    className="py-4 odd:bg-purple-50 -mx-4 px-5"
                    selected={get(filters, key, [])}
                    onAdd={(option) => {
                      setFilter(key, [...get(filters, key, []), option]);
                    }}
                    onRemove={(option) => {
                      const newFilters = pull(get(filters, key, []), option);
                      if (size(newFilters) === 0) {
                        removeFilter(key);
                        return;
                      }
                      setFilter(key, newFilters);
                    }}
                  />
                );
              })}
              
            </div>

            <div>
              <div
                className="mt-3 underline cursor-pointer"
                onClick={() => {
                  resetFilters();
                }}
              >
                Supprimer les filtres
              </div>
            </div>

            <button
              className="btn mt-5 w-full"
              type="button"
              onClick={() => toggleFilters(false)}
            >
              Appliquer les filtres
            </button>
          </Modal>
        </div>
        <div className="px-8 mb-48">
          <React.Suspense fallback={<Spinner />}>
            <AccreditationsList
              query={debouncedQuery}
              filters={{ ...filters }}
            />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}

export default AffectedAccreditations;
