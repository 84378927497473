import React, { useState } from "react";
import { flatMap, get, map, size } from "lodash-es";
import { useDebounce } from "react-use";
import Alert from "../../components/Alert";
import ExpansionPanel from "../../components/ExpansionPanel";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroller";
import { Link } from "react-router-dom";
import Modal from "../../components/Modal";
import {
  PermissionChecker,
  usePermissionsChecker,
} from "../../contexts/permissions";
import { ReactComponent as PlusIcon } from "../../svgs/plus.svg";
import Spinner from "../../components/Spinner";
import { useDesktop } from "../../hooks/useDesktop";
import { ReactComponent as TrashIcon } from "../../svgs/trash.svg";
import { useQueryAccreditationsInfinite } from "../../api/accreditation";
import DeleteAccreditation from "../../forms/DeleteAccreditation";
import CreateAccreditationForm from "../../forms/CreateAccreditationForm";
import CustomLink from "../../components/CustomLink";
import {HeaderBar} from "../../components/HeaderBar";
import InlineBLockContentSortable from "../../components/InlineBlockContentSortable";

function InlineBLockContent({ children, className = "" }) {
  return (
    <div className={`px-4 flex items-center ${className}`}>{children}</div>
  );
}

function AccreditationMobileCard({ accreditation }) {
  return (
    <ExpansionPanel
      key={accreditation.id}
      title={`${accreditation.label}`}
      subtitle={get(accreditation, "licencePlate")}
    >
      <CreateAccreditationForm accreditation={accreditation} readOnly />
      <Link
        className="btn mt-6 w-full block text-center"
        to={`/rh/habilitations/habilitation/${accreditation.id}`}
      >
        Modifier
      </Link>
    </ExpansionPanel>
  );
}

function AccreditationsList({ query }) {
  const isDesktop = useDesktop();

  const [orderFilter, setOrderFilter] = useState({
    "order[label]": "asc",
  });
  const { data, fetchMore, canFetchMore } = useQueryAccreditationsInfinite({
              ...orderFilter,
              label: query,
  });
  const [modalDeleteAccreditation, setModalDeleteAccreditation] = useState(
    false
  );
  const hasPermissionsManage = usePermissionsChecker({
    permissions: [
      "kdix.actions.accreditation.manage",
      "kdix.actions.accreditation.manage.agency",
      "kdix.actions.accreditation.manage.department",
      "kdix.actions.accreditation.manage.service",
      "kdix.actions.accreditation.manage.own",
    ],
  });
  
  const accreditations = flatMap(data, (page) => page["hydra:member"]);
  if (size(accreditations) <= 0) {
    return (
      <div className="mt-12">
        <Alert type="warning" message="aucune habilitation" />
      </div>
    );
  }

  const gridTemplateColumn = `35% ${hasPermissionsManage ? "55% 10%" : "65%"}`;
  return (
    <div className={`mt-8`}>
      {isDesktop && (
        <div
          className={`grid divide-x text-white divide-white bg-gray-500 shadow mb-2 py-2 sticky top-tab-header always-front`}
          style={{
            gridTemplateColumns: gridTemplateColumn,
          }}
        >
          <InlineBLockContentSortable
           setOrderFilter={setOrderFilter}
           colName={"label"}
          >
          Nom
          </InlineBLockContentSortable>
          <InlineBLockContent>Description</InlineBLockContent>
          {hasPermissionsManage && (
            <InlineBLockContent className="justify-center">
              Action
            </InlineBLockContent>
          )}
        </div>
      )}

      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={() => {
          fetchMore();
        }}
        hasMore={canFetchMore !== false}
        loader={
          <div key={0} className=" relative">
            <Spinner />
          </div>
        }
      >
        {isDesktop
          ? map(accreditations, (node) => {
              return (
                <CustomLink
                  to={`/rh/habilitations/habilitation/${node.id}`}
                  key={node.id}
                  className={`grid divide-x divide-gray-100 bg-white hover:bg-purple-50 shadow mb-2 py-2`}
                  style={{
                    gridTemplateColumns: gridTemplateColumn,
                  }}
                  disabled={!hasPermissionsManage}
                >
                  <InlineBLockContent>{node.label}</InlineBLockContent>
                  <InlineBLockContent>{node.description}</InlineBLockContent>
                  {hasPermissionsManage && (
                    <InlineBLockContent className="justify-center">
                      <button
                        className="text-red-500 focus:outline-none focus:shadow-none"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setModalDeleteAccreditation(node.id);
                        }}
                      >
                        <TrashIcon className="w-6 h-6 text-red-500 fill-current" />
                      </button>
                    </InlineBLockContent>
                  )}
                </CustomLink>
              );
            })
          : map(accreditations, (node) => (
              <AccreditationMobileCard key={node.id} accreditation={node} />
            ))}
      </InfiniteScroll>

      <Modal
        title="Supprimer une habilitation"
        handleClose={(e) => {
          e.stopPropagation();
        }}
        isOpen={modalDeleteAccreditation !== false}
        onRequestClose={() => setModalDeleteAccreditation(false)}
      >
        <div className="bg-white">
          <DeleteAccreditation
            id={modalDeleteAccreditation}
            onSuccess={() => setModalDeleteAccreditation(false)}
          />
        </div>
      </Modal>
    </div>
  );
}

function Catalogue() {
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  const [,] = useDebounce(
    () => {
      setDebouncedQuery(query);
    },
    500,
    [query]
  );

  React.useEffect(() => {
            var _paq = window._paq = window._paq || [];
            _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
       
            var u="//matomo.kdix.pockost.com/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '1']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    }, [])

  return (
    <div>
      <Helmet>
        <title>Catalogue Habilitations</title>
      </Helmet>
      <HeaderBar
          sticky={true} smaller={true}
          title={
              <>
                  Catalogue des habilitations
              </>
          }
          buttons={
              <PermissionChecker
                  permissions={[
                      "kdix.actions.accreditation.manage",
                      "kdix.actions.accreditation.manage.agency",
                      "kdix.actions.accreditation.manage.department",
                      "kdix.actions.accreditation.manage.service",
                      "kdix.actions.accreditation.manage.own",
                  ]}
                  notAllowed={null}
              >
                  <Link
                      to="/rh/habilitations/creation-habilitation"
                      className="absolute bg-green-600 hover:bg-green-800 text-white rounded-full w-12 h-12 -mb-5 mr-5 bottom-0 right-0 flex justify-center items-center focus:outline-none"
                  >
                      <PlusIcon className="w-4 h-4" />
                  </Link>
              </PermissionChecker>
          }
      >
          <input
              type="text"
              name="query"
              value={query}
              className="mb-0 appearance-none bg-transparent border-b border-white w-full py-2  leading-tight focus:outline-none focus:border-red"
              placeholder="Rechercher une habilitation"
              onChange={(e) => setQuery(e.target.value)}
          />
      </HeaderBar>

      <div>
        <div className="px-8 mb-48">
          <React.Suspense fallback={<Spinner />}>
            <AccreditationsList query={debouncedQuery} />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}

export default Catalogue;
