import React, { useState } from "react";
import { Form, Formik, useFormikContext } from "formik";
import { get, map, reduce, noop, isEmpty } from "lodash-es";
import Alert from "../components/Alert";
import Input, { DateTimePickerInput } from "../components/Input";
import { useHistory } from "react-router-dom";
import Button from "../components/Button";
import * as Yup from "yup";
import { usePermissionsChecker, useUseExternalDocumentManagement } from "../contexts/permissions";
import { useCreateOrUpdateWorkAccident } from "../api/workAccident";
import SelectCollaborator from "../components/SelectCollaborator";
import { useQueryTypeWorkAccidentLocation } from "../api/typeWorkAccidentLocation";
import Select from "../components/Select";
import { useQueryAgencies } from "../api/agencies";
import { useQueryTypeWorkAccident } from "../api/typeWorkAccident";
import {
  getCpamValueForApi,
  getCpamValueForSelect,
  getLabelCpamByValue,
  getSelectValues,
} from "../utils/cpamStatut";
import { useQueryTypeWorkAccidentBodyArea } from "../api/typeWorkAccidentBodyArea";
import { useList } from "react-use";
import { useGetCollaboratorAbsenceTypes } from "../api/collaborator";
import ExpansionPanel from "../components/ExpansionPanel";
import { getLocaleDateString } from "../utils/date";
import { useDesktop } from "../hooks/useDesktop";
import { useCreateOrUpdateAbsence } from "../api/absence";
import { useCreateAbsenceDocument } from "../api/absenceDocument";
import TextArea from "../components/TextArea";
import { getFirstnameLastnameJob } from "../utils/names";
import Modal from "../components/Modal";
import AbsenceForm from "./AbsenceForm";
import DownloadOrUploadMulti from "../components/DownloadOrUploadMulti";
import {
  downloadWorkAccidentDocument,
  useCreateWorkAccidentDocument,
  useDeleteWorkAccidentDocument,
  useQueryWorkAccidentDocuments,
} from "../api/workAccidentDocument";
import DocumentOnlyBrowser from "../components/DocumentOnlyBrowser";
import Spinner from "../components/Spinner";
import {
    dateCompare,
} from "../regex/date";

const schema = Yup.object().shape({
  victim: Yup.object()
    .test({
      test: ({ value }) => value,
      message: "Requis",
    })
    .required("Requis"),
  declarer: Yup.object()
    .test({
      test: ({ value }) => value,
      message: "Requis",
    })
    .required("Requis"),
  date: Yup.date().required("Requis").test(
            "date-match", 
            "Doit être antérieure à la date du jour",
            function (value) {
                const today = new Date();
                today.setHours(23, 59, 59, 0);
                return dateCompare(value, today, false);
            }
          ),
  dateInvestigation: Yup.date().nullable().test(
            "date-match", 
            "Doit être postérieure à la date de l'accident",
            function (value) {
                if(!value || value === 0) {
                    return true;
                } 
                const diffDay = Math.floor((value - this.parent.date) / (1000 * 60 * 60 * 24));
                return diffDay >= 0;
            }
          ),
  agency: Yup.object()
    .test({
      test: ({ value }) => value,
      message: "Requis",
    })
    .required("Requis"),
  description: Yup.string().required("Requis"),
});

function selectInitialValues(names, workAccident = null) {
  return reduce(
    names,
    (result, name) => {
      result[name] = {
        label:
          name === "victim" || name === "declarer"
            ? getFirstnameLastnameJob(workAccident, name)
            : get(workAccident, `${name}.label`, ""),
        value: get(workAccident, `${name}[@id]`, ""),
      };
      return result;
    },
    {}
  );
}

function CreateWorkAccidentForm({
  workAccident = null,
  readOnly = false,
  full = false,
}) {
  const history = useHistory();
  const useExternalDocumentManagement = useUseExternalDocumentManagement();
  const [createOrUpdate, { error }] = useCreateOrUpdateWorkAccident();
  const [createUpdateAbsence] = useCreateOrUpdateAbsence();
  const [modalAbsenceOpen, setModalAbsenceOpen] = useState(false);
  const { data: typesAccLocation } = useQueryTypeWorkAccidentLocation();
  const { data: bodyAreas } = useQueryTypeWorkAccidentBodyArea();
  const { data: typesAccident } = useQueryTypeWorkAccident();
  const { data: agencies } = useQueryAgencies();
  const [createDocument] = useCreateAbsenceDocument();
  const [createWorkAccidentDocument] = useCreateWorkAccidentDocument();
  const canView = usePermissionsChecker({
    permissions: [
      "kdix.actions.work_accident.view",
      "kdix.actions.work_accident.view.agency",
      "kdix.actions.work_accident.view.department",
      "kdix.actions.work_accident.view.service",
      "kdix.actions.work_accident.view.own",
    ],
  });
  const canCreate = usePermissionsChecker({
    permissions: [
      "kdix.actions.work_accident.edit",
      "kdix.actions.work_accident.edit.agency",
      "kdix.actions.work_accident.edit.department",
      "kdix.actions.work_accident.edit.service",
      "kdix.actions.work_accident.edit.own",
    ],
  });
  const canCreateAbsence = usePermissionsChecker({
    permissions: [
        "kdix.actions.absence.edit",
        "kdix.actions.absence.edit.agency",
        "kdix.actions.absence.edit.department",
        "kdix.actions.absence.edit.service",
        "kdix.actions.absence.edit.own",
    ],
  });
  const isDesktop = useDesktop();
  const [
    listAbsences,
    { push: pushAbsence, updateAt: updateAbsenceAt },
  ] = useList(get(workAccident, "absences", []));
  const isEditing = !!get(workAccident, "id", false);

  const SubmitButton = ({ isSubmitting, textLabel }) => {
    const { submitForm } = useFormikContext();
    return (
      <div className="text-center">
        <Button
          className={`btn my-8 px-8`}
          disabled={isSubmitting}
          isSubmitting={isSubmitting}
          isForm={true}
          onClick={() => submitForm()}
          textLabel={textLabel}
        />
      </div>
    );
  };

  return canView ? (
    <div>
      <div className={isDesktop && !full ? "grid-cols-2 grid gap-6" : ""}>
        <Formik
          initialValues={{
            description: get(workAccident, "description", ""),
            ...selectInitialValues(
              ["victim", "declarer", "location", "type", "agency", "bodyArea"],
              workAccident
            ),
            accepted: {
              label: getLabelCpamByValue(get(workAccident, "accepted")),
              value: getCpamValueForSelect(get(workAccident, "accepted", null)),
            },
            date: get(workAccident, "date", ""),
            dateInvestigation: get(workAccident, "dateInvestigation", ""),
            actions: get(workAccident, "actions", ""),
            files: [],
          }}
          enableReinitialize={true}
          validationSchema={schema}
          onSubmit={async (
            {
              bodyArea,
              agency,
              type,
              accepted,
              location,
              declarer,
              victim,
              dateInvestigation,
              ...values
            },
            actions
          ) => {
            try {
              const data = {
                agency: agency.value ? agency.value : null,
                bodyArea: bodyArea.value ? bodyArea.value : null,
                type: type.value ? type.value : null,
                accepted: getCpamValueForApi(accepted.value),
                location: location.value ? location.value : null,
                declarer: declarer.value ? declarer.value : null,
                victim: victim.value ? victim.value : null,
                dateInvestigation: dateInvestigation ? dateInvestigation : null,
                ...values,
              };

              const response = await createOrUpdate({
                id: get(workAccident, "id", null),
                data: data,
              });

              if (response?.id && values?.files) {
                for (const file of values.files) {
                  await createWorkAccidentDocument({
                    file: file,
                    workAccidentId: response.id,
                  });
                }
              }
              if (!isEditing) {
                data.absences = listAbsences
                  ? map(
                      listAbsences,
                      async ({ type, absenceDocument: file, ...absence }) => {
                        const { id: newId } = await createUpdateAbsence({
                          id: get(absence, "id", null),
                          data: {
                            comment: absence.comment,
                            startDate: absence.startDate,
                            endDate: absence.endDate,
                            collaborator: victim.value ? victim.value : null,
                            type: type,
                            workAccident: get(response, "@id", null),
                          },
                        });
                        if (newId && file) {
                          await createDocument({
                            file: file,
                            absenceId: newId,
                          });
                        }
                      }
                    )
                  : null;
              }

              actions.setSubmitting(false);
              actions.resetForm();
              if (!response.status && response.id) {
                history.push(`/prevention/accident/${response.id}`);
              }
            } catch (error) {
              map(get(error, "violations"), (e) => {
                actions.setFieldError(e.propertyPath, e.message);
              });
            }
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => {
            return (
              <div>
                <Form>
                  <SelectCollaborator
                    name="victim"
                    label="Victime"
                    value={values.victim}
                    isDisabled={readOnly || get(workAccident, "id", false)}
                  />
                  <SelectCollaborator
                    name="declarer"
                    label="Déclarant"
                    value={values.declarer}
                    isDisabled={readOnly}
                  />
                  <Select
                    name="bodyArea"
                    label="Localisation corporelle"
                    placeholder="sélectionner..."
                    options={map(bodyAreas, (bodyArea) => ({
                      label: bodyArea.label,
                      value: bodyArea["@id"],
                    }))}
                    value={
                      values.bodyArea.value !== "" ? values.bodyArea : null
                    }
                    isLoading={!bodyAreas}
                    isDisabled={readOnly}
                  />
                  <Select
                    name="agency"
                    label="Site"
                    options={map(agencies, (agency) => ({
                      label: `${agency.label}`,
                      value: agency["@id"],
                    }))}
                    value={values.agency.value !== "" ? values.agency : null}
                    isLoading={!agencies}
                    isDisabled={readOnly}
                  />
                  <Select
                    name="type"
                    label="Type d'accident"
                    placeholder="sélectionner..."
                    options={map(typesAccident, (typeAccident) => ({
                      label: typeAccident.label,
                      value: typeAccident["@id"],
                    }))}
                    value={values.type.value !== "" ? values.type : null}
                    isLoading={!typesAccident}
                    isDisabled={readOnly}
                  />
                  <Select
                    name="location"
                    label="Type de lieu"
                    placeholder="sélectionner..."
                    options={map(typesAccLocation, (typeAccLocation) => ({
                      label: typeAccLocation.label,
                      value: typeAccLocation["@id"],
                    }))}
                    value={
                      values.location.value !== "" ? values.location : null
                    }
                    isLoading={!typesAccLocation}
                    isDisabled={readOnly}
                  />
                  <DateTimePickerInput
                    label="Date et heure"
                    name="date"
                    fullWidth={true}
                    disableClock={false}
                    onChange={(value) => {
                      setFieldValue("date", value);
                    }}
                    disabled={readOnly}
                  />
                  <Select
                    name="accepted"
                    label="Statut CPAM"
                    placeholder="sélectionner..."
                    options={getSelectValues()}
                    value={
                      values.accepted.value !== "" ? values.accepted : null
                    }
                    isLoading={!typesAccLocation}
                    isDisabled={readOnly}
                  />
                  <div className={"pb-4"}>
                    <TextArea
                      label="Description"
                      name="description"
                      textareaClassName="comment"
                      readOnly={readOnly}
                    />
                  </div>
                  <DateTimePickerInput
                    label="Date de l'enquête"
                    name="dateInvestigation"
                    fullWidth={true}
                    disabled={readOnly}
                  />
                  <Input
                    type="text"
                    name="actions"
                    label="Actions menées"
                    readOnly={readOnly}
                  />
                  {workAccident?.["@id"] && (
                    <React.Suspense fallback={<Spinner />}>
                      <div className="mb-2">
                        <DocumentOnlyBrowser
                          queryFolderHook={useQueryWorkAccidentDocuments.bind(
                            null,
                            {
                              workAccident: workAccident?.["@id"],
                            }
                          )}
                          deleteDocumentHook={useDeleteWorkAccidentDocument}
                          downloadDocumentFn={downloadWorkAccidentDocument}
                          canDelete={!useExternalDocumentManagement}
                        />
                      </div>
                    </React.Suspense>
                  )}
                  {!useExternalDocumentManagement && (
                  <DownloadOrUploadMulti
                    name="files"
                    accept="application/pdf, application/docx, image/jpeg, image/png, image/gif, image/webm"
                    isSubmitting={isSubmitting}
                    submitButton={false}
                    multiple
                  />
                  )}
                  {error ? (
                    <div className="my-2">
                      <Alert
                        type="error"
                        message={get(error, "title")}
                        details={get(error, "description")}
                      />
                    </div>
                  ) : null}
                  {!readOnly && canCreate && (
                    <SubmitButton
                      isSubmitting={isSubmitting}
                      textLabel={
                        workAccident
                          ? "Enregistrer l'accident du travail"
                          : "Créer le nouvel accident du travail"
                      }
                    />
                  )}
                </Form>
              </div>
            );
          }}
        </Formik>
        { canCreateAbsence && (
        <div>
          <div className="text-lg font-bold mb-4">
            Absences liées à l'accident
          </div>
          {isEmpty(listAbsences) && (
            <div className={"mb-4"}>
              <Alert
                type="warning"
                message="Aucune absence liée à cet accident"
              />
            </div>
          )}
          {map(listAbsences, (absence, key) => (
            <ExpansionPanel
              title={get(absence, "type.label", "Absence")}
              subtitle={`${getLocaleDateString(
                absence,
                "startDate"
              )} - ${getLocaleDateString(absence, "endDate")}`}
              key={key}
            >
              <div className="mb-8">
                <FormLinkedAbsence
                  pushAbsence={pushAbsence}
                  absence={absence}
                  isEditing={isEditing}
                  readOnly={readOnly}
                  updateAt={(absence) => updateAbsenceAt(key, absence)}
                  workAccident={workAccident}
                  isModification={true}
                  noRedirect={true}
                />
              </div>
            </ExpansionPanel>
          ))}
          {!readOnly && (
            <div className={"flex items-center"}>
              <div
                className="btn leading-none focus:outline-none focus:shadow-none mx-auto"
                onClick={() => setModalAbsenceOpen(true)}
              >
                Ajouter une absence
              </div>
            </div>
          )}
        </div>
        )}
      </div>
      { canCreateAbsence && (
      <Modal
        title="Ajouter une absence"
        handleClose={(e) => {
          e.stopPropagation();
        }}
        isOpen={modalAbsenceOpen}
        onRequestClose={() => setModalAbsenceOpen(false)}
      >
        <div className="bg-white">
          <FormLinkedAbsence
            pushAbsence={pushAbsence}
            isEditing={isEditing}
            workAccident={workAccident}
            handleSubmit={() => setModalAbsenceOpen(false)}
            noRedirect={true}
          />
        </div>
      </Modal>
      )}
    </div>
  ) : null;
}

function FormLinkedAbsence({
  pushAbsence = noop,
  absence = null,
  workAccident = null,
  isEditing = false,
  readOnly = false,
  updateAt = noop,
  handleSubmit = () => {},
  isModification = false,
  noRedirect = false,
}) {
  const iriSplit = get(workAccident, "victim[@id]", "").split("/");
  const id = get(iriSplit, `${iriSplit.length - 1}`, null);
  const { data: typesAbsence } = useGetCollaboratorAbsenceTypes(id, {
    workAccidentType: true,
  });

  if (!id) {
    return (
      <div>
        Pour lier une absence à l'accident merci de d'abord valider l'accident
      </div>
    );
  }
  return (
    <AbsenceForm
      pushAbsence={pushAbsence}
      absence={absence}
      workAccident={workAccident}
      isEditing={isEditing}
      readOnly={readOnly}
      updateAt={updateAt}
      onSuccess={handleSubmit}
      typesAbsence={typesAbsence}
      collaboratorIRI={get(workAccident, "victim[@id]", null)}
      isWorkAccidentModification={isModification}
      noRedirect={noRedirect}
    />
  );
}

export default CreateWorkAccidentForm;
