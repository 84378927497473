import * as React from "react";

import { usePermissionsChecker } from "./permissions";

import Alert from "../components/Alert";
import ErrorBoundary from "../components/ErrorBoundary";
import Spinner from "../components/Spinner";
import { get, map } from "lodash-es";
import { useQueryCollaboratorById } from "../api/collaborator";
import { useQueryContractById } from "../api/contract";
import { useQueryPersonalInformationById } from "../api/personalInformation";
import { useQueryVehicleById } from "../api/vehicle";

export const CollaboratorContext = React.createContext({
  collaborator: null,
});

function CollaboratorError(error) {
  return (
    <div className="mb-48">
      <Alert
        type="error"
        message="Erreur lors de la récupération du collaborateur"
        details={get(error, "data.hydra:description")}
      />
    </div>
  );
}

function CollaboratorProvider({ id, children }) {
  const {
    status: statusCollaborator,
    error: errorCollaborator,
    data: collaborator,
  } = useQueryCollaboratorById(id);

  const full = usePermissionsChecker({
    permissions: [
      "kdix.actions.personal_information.view",
      "kdix.actions.personal_information.view.agency",
      "kdix.actions.personal_information.view.department",
      "kdix.actions.personal_information.view.service",
      "kdix.actions.personal_information.view.own",
    ],
    userId: collaborator?.linkedUser?.id,
    agencies: collaborator.agencies ? map(collaborator.agencies, "@id") : null,
  });
  
  const fullAdditionalInfo = usePermissionsChecker({
    permissions: [
      "kdix.actions.personal_information.view",
      "kdix.actions.personal_information.view.agency",
      "kdix.actions.personal_information.view.department",
      "kdix.actions.personal_information.view.service",
      "kdix.actions.personal_information.view.own",
      "kdix.actions.additional_information.view",
      "kdix.actions.additional_information.view.agency",
      "kdix.actions.additional_information.view.department",
      "kdix.actions.additional_information.view.service",
      "kdix.actions.additional_information.view.own",
    ],
    userId: collaborator?.linkedUser?.id,
    agencies: collaborator.agencies ? map(collaborator.agencies, "@id") : null,
  });

  const fullVehicle = usePermissionsChecker({
    permissions: [
      "kdix.actions.vehicle.view",
      "kdix.actions.vehicle.view.agency",
      "kdix.actions.vehicle.view.department",
      "kdix.actions.vehicle.view.service",
      "kdix.actions.vehicle.view.own",
    ],
    userId: collaborator?.linkedUser?.id,
    agencies: collaborator.agencies ? map(collaborator.agencies, "@id") : null,
  });

  const {
    status: statusCurrentContract,
    error: errorCurrentCOntract,
    data: currentContract,
  } = useQueryContractById(full && get(collaborator, "currentContract.id"));

  const {
    status: statusPersonalInformation,
    error: errorPersonalInformation,
    data: personalInformation,
  } = useQueryPersonalInformationById(
    fullAdditionalInfo && get(collaborator, "personalInformation.id")
  );

  const {
    status: statusVehicle,
    error: errorVehicle,
    data: vehicle,
  } = useQueryVehicleById(fullVehicle && get(collaborator, "firstVehicle.id"));
  if (errorCollaborator) {
    return <CollaboratorError error={errorCollaborator} />;
  }

  return (
    <CollaboratorContext.Provider
      value={{
        collaborator: {
          data: collaborator || {},
          status: statusCollaborator,
          error: errorCollaborator,
        },
        currentContract: {
          data: currentContract || {},
          status: statusCurrentContract,
          error: errorCurrentCOntract,
        },
        personalInformation: {
          data: personalInformation || {},
          status: statusPersonalInformation,
          error: errorPersonalInformation,
        },
        vehicle: {
          data: vehicle || {},
          status: statusVehicle,
          error: errorVehicle,
        },
      }}
    >
      {children}
    </CollaboratorContext.Provider>
  );
}

export default (props) => (
  <ErrorBoundary>
    <React.Suspense fallback={<Spinner />}>
      <CollaboratorProvider {...props} />
    </React.Suspense>
  </ErrorBoundary>
);
