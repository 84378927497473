import AbsenceHistoryItem from "./HistoryItem/AbsenceHistoryItem";
import Alert from "./Alert";
import { ReactComponent as ArrowDownRightIcon } from "../svgs/ArrowDownRight.svg";
import { ReactComponent as ArrowUpRightIcon } from "../svgs/ArrowUpRight.svg";
import BountyHistoryItem from "./HistoryItem/BountyHistoryItem";
import EventHistoryItem from "./HistoryItem/EventHistoryItem";
import HistoryItem from "./HistoryItem/HistoryItem";
import ContractHistoryItem from "./HistoryItem/ContractHistoryItem";
import WorkAccidentHistoryItem from "./HistoryItem/WorkAccidentHistoryItem";
import SalaryHistoryItem from "./HistoryItem/SalaryHistoryItem";

import React from "react";
import RequestHistoryItem from "./HistoryItem/RequestHistoryItem";
import { get, map } from "lodash-es";
import { usePermissionsChecker } from "../contexts/permissions";
import LokoaRequestHistoryItem from "./HistoryItem/LokoaRequestHistoryItem";

function ActionsHistory({ hiredate, departureDate, items, collaborator }) {
  const canViewAbsence = usePermissionsChecker({
    permissions: [
      "kdix.actions.absence.view",
      "kdix.actions.absence.view.agency",
      "kdix.actions.absence.view.department",
      "kdix.actions.absence.view.service",
      "kdix.actions.absence.view.own",
    ],
  });
  const canViewContract = usePermissionsChecker({
    permissions: [
      "kdix.actions.personal_information.view",
      "kdix.actions.personal_information.view.agency",
      "kdix.actions.personal_information.view.department",
      "kdix.actions.personal_information.view.service",
      "kdix.actions.personal_information.view.own",
    ],
  });
  const canViewLokoaRequests = usePermissionsChecker({
    permissions: [
      "kdix.actions.lokoa.view",
    ],
  });
  if (items && items.length === 0) {
    return (
      <Alert type="warning" details="Aucun historique pour ce collaborateur" />
    );
  }
  const itemsList = [
    ...items,
    { date: new Date().toISOString(), type: "NOW", event: { "@id": "now" } },
  ].sort((a, b) => (a.date < b.date ? 1 : -1));
  let isLeft = items && departureDate;

  return (
    <div
      style={{
        background: items
          ? "linear-gradient(90deg, rgba(0,0,0,0) calc(50% - 1px), rgba(0,0,0,1) calc(50%), rgba(0,0,0,0) calc(50% + 1px))"
          : "",
      }}
    >
      {items && departureDate ? (
        <HistoryItem
          title="Départ"
          date={departureDate}
          Icon={ArrowUpRightIcon}
          backgroundIcon="bg-orange-250"
          isLeft={true}
        />
      ) : null}

      {map(itemsList, (item, index) => {
        isLeft = !isLeft;
        switch (item.type) {
          case "NOW":
            const today = new Date(item.date).toLocaleDateString();
            const isFirst =
              index === 0 && (!departureDate || departureDate < today);
            const isLast =
              index === itemsList.length - 1 && (!hiredate || hiredate > today);
            //On rechange la valeur de isLeft car cet élément est sur toute la ligne
            isLeft = !isLeft;
            return (
              <div key={get(item, "event.@id", index)}>
                {!isFirst && !isLast && (
                  <div className={"xl:mb-6 mb-6 todaySeparator"} />
                )}
              </div>
            );
          case "App\\Entity\\Contract":
            return canViewContract ? (
              <ContractHistoryItem
                key={index}
                contract={item.event}
                isLeft={isLeft}
              />
            ) : null;
          case "App\\Entity\\Salary":
            return canViewContract ? (
              <SalaryHistoryItem
                key={index}
                salary={item.event}
                isLeft={isLeft}
              />
            ) : null;
          case "App\\Entity\\LokoaRequest":
            return canViewLokoaRequests ? (
                <LokoaRequestHistoryItem
                    key={get(item, "event.@id", index)}
                    request={item.event}
                    date={item.date}
                    isLeft={isLeft}
                />
            ) : null;
          case "App\\Entity\\Request":
            return (
              <RequestHistoryItem
                key={get(item, "event.@id", index)}
                request={item.event}
                date={item.date}
                isLeft={isLeft}
              />
            );
          case "App\\Entity\\Absence":
            return canViewAbsence ? (
              <AbsenceHistoryItem
                key={get(item, "event.@id", index)}
                absence={item.event}
                isLeft={isLeft}
                collaborator={collaborator}
              />
            ) : null;
          case "App\\Entity\\Bounty":
            return canViewContract ? (
              <BountyHistoryItem
                key={get(item, "event.@id", index)}
                bounty={item.event}
                date={item.date}
                isLeft={isLeft}
              />
            ) : null;
          case "App\\Entity\\Event":
            return (
              <EventHistoryItem
                key={get(item, "event.@id", index)}
                date={item.date}
                event={item.event}
                collaborator={collaborator}
                isLeft={isLeft}
              />
            );
          case "App\\Entity\\WorkAccident":
            return (
              <WorkAccidentHistoryItem
                key={get(item, "event.@id", index)}
                date={item.date}
                workAccident={item.event}
                isLeft={isLeft}
              />
            );
          default:
            return (
              <HistoryItem
                key={get(item, "event.@id", index)}
                {...item}
                isLeft={isLeft}
              />
            );
        }
      })}

      {items && hiredate ? (
        <HistoryItem
          title="Embauche"
          date={hiredate}
          Icon={ArrowDownRightIcon}
          backgroundIcon="bg-teal-50"
          isLeft={!isLeft} //La valeur n'étant pas changé après le dernier élément de la boucle
        />
      ) : null}
    </div>
  );
}

export default React.memo(ActionsHistory);
