import { Form, Formik } from "formik";
import {get, isEmpty, map, mapValues, size, values as valuesL} from "lodash-es";

import Alert from "./Alert";
import Button from "./Button";
import Input from "./Input";
import React, {useState} from "react";
import Select from "./Select";
import mapFormValues from "../utils/mapFormValues";
import { toast } from "react-toastify";
import Checkbox from "./Checkbox";
import * as Yup from "yup";
import { Helmet } from "react-helmet";
import Spinner from "./Spinner";
import {useDebounce} from "react-use";
import { ReactComponent as PenIcon } from "../svgs/pen.svg";
import { ReactComponent as TrashIcon } from "../svgs/trash.svg";
import { ReactComponent as RecurrentIcon } from "../svgs/check.svg";
import { ReactComponent as NotRecurrentIcon } from "../svgs/cross.svg";
import Modal from "./Modal";
import { ReactComponent as PlusIcon } from "../svgs/plus.svg";
import Toggle from "./Toogle";
import { HeaderBar } from "./HeaderBar";
import UploadAvatar from "./UploadAvatar";
import arrayMove from "array-move";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

function mapInitialValues(fields) {
  return mapValues(fields, ({ resourceValueKey, ...field }) => {
    if (resourceValueKey === undefined && size(field) > 0) {
      return mapInitialValues(field);
    }
    if (field.type === "checkbox" && field.value !== true) {
      return false;
    }
    return field.value || field.initialValue || "";
  });
}

// obj, str -> component
function mapInputs({ fields, path = "", values }) {
  return map(fields, ({ resourceValueKey, initialValue, ...field }, key) => {
    const name = `${path}${key}`;
    if (resourceValueKey === undefined && size(field) > 0) {
      return (
        <div key={name}>
          {mapInputs({ field: field, path: `${key}.`, values: values })}
        </div>
      );
    }

    if (field.showif && !get(values, field.showif, false)) return null;
    if (field.type === "select") {
      return (
        <Select
          {...field}
          key={name}
          name={name}
          options={field.options || []}
          className="last:mb-0 mb-4 flex-1"
        />
      );
    } else if (field.type === "checkbox") {
      return <Checkbox {...field} type="checkbox" key={name} name={name} />;
    }

    return <Input {...field} key={name} name={name} />;
  });
}

// obj, obj -> obj
function mapResourceToFields(resource, fields) {
  return mapValues(fields, (field) => {
    if (field.resourceValueKey === undefined && size(field) > 0) {
      return mapResourceToFields(resource, field);
    }

    const value = get(resource, field.resourceValueKey, "");

    if (
      field.transformResourceValue &&
      typeof field.transformResourceValue === "function"
    ) {
      return {
        ...field,
        value: field.transformResourceValue(value),
      };
    }

    return {
      ...field,
      value,
    };
  });
}

// obj, obj -> obj
function mapIsEditing(fields, IsEditing = false) {
  return mapValues(fields, (field) => {
    if (field.resourceValueKey === undefined && size(field) > 0) {
      return mapIsEditing(field, IsEditing);
    }

    if (field.type === "select") {
      return {
        ...field,
        isDisabled: !IsEditing,
      };
    }

    return {
      ...field,
      readOnly: !IsEditing,
    };
  });
}

function EditResource({
  initialValues = {},
  fieldsConfig = {},
  onSubmit = () => {},
  onSubmitEnd = () => {},
  onCancel = () => {},
  readOnly,
  validation,
}) {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={async (values, actions) => {
        try {
          await onSubmit(mapFormValues(values));
        } catch (error) {
          map(get(error, "violations"), (e) => {
            actions.setFieldError(e.propertyPath, e.message);
          });
        } finally {
          actions.setSubmitting(false);
          await onSubmitEnd();
        }
      }}
    >
      {({ isSubmitting, values, resetForm }) => {
        return (
          <Form>
            <div className="">
              {mapInputs({ fields: fieldsConfig, values: values })}
              {!readOnly ? (
                <div className="mt-4 align-top">
                  <Button
                    className={`btn btn--sm`}
                    isSubmitting={isSubmitting}
                    isForm={true}
                    loaderClassName={`loader h-6 w-6 mx-auto`}
                    type="submit"
                    textLabel="Enregistrer"
                  />

                  <Button
                    className={`btn btn--sm bg-orange-500 hover:bg-orange-700 ml-4 align-top`}
                    type="button"
                    textLabel="Annuler"
                    onClick={() => {
                      resetForm();
                      onCancel();
                    }}
                  >
                    Annuler
                  </Button>
                </div>
              ) : null}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

function DeleteButton({ deleteHook, resourceId, setLastError, isDeactivated }) {
  const [IsDeleting, setIsDeleting] = React.useState(false);
  const [
    deleteResource,
    { status: statusDelete, error: deleteError },
  ] = deleteHook();
  React.useEffect(() => {
    setLastError(deleteError);
  }, [deleteError, setLastError]);

  if (statusDelete === "success") return null;
  return (
    <Button
      type="button"
      superClassName={`w-8 h-8 rounded-full flex justify-center items-center focus:outline-none ${
        isDeactivated ? "bg-gray-300" : "bg-red-600"
      } `}
      isSubmitting={IsDeleting}
      loaderClassName={`loader h-6 w-6 mx-auto`}
      onClick={async () => {
        const res = window.confirm("La suppression sera définitive");
        if (res) {
          setIsDeleting(true);
          deleteResource(resourceId);
        }
      }}
    >
      <TrashIcon className="w-4 h-4 fill-current text-white" />
    </Button>
  );
}

function Resource({
  resource,
  fieldsConfig,
  updateHook,
  deleteHook,
  canDelete = true,
  readOnly,
  fieldTitle,
  fieldSubtitle,
  colorField,
  isRecurrentField,
  canDeactivate = false,
  SpecificForm,
  validation,
  SpecificMapFormValues,
  imageUpload = false,
}) {
  if (!resource.id) {
    throw new Error("resource.id is mandatory");
  }
  const [lastError, setLastError] = React.useState(null);
  const [updateResource, { error: updateError }] = updateHook();
  const [modalEditOpen, setModalEditOpen] = React.useState(false);
  const [isActivating, setIsActivating] = React.useState(false);
  const isDeactivated = canDeactivate && !get(resource, "active", false);
  React.useEffect(() => {
    setLastError(updateError);
  }, [updateError, setLastError]);

  const avatar = get(resource, "icon.contentUrl");
  const resourceId = get(resource, "id");

  return (
    <>
      <div
        className="grid gap-4 items-center py-4 text-lg border-solid border-t border-gray-200"
        style={{
          gridTemplateColumns: `auto ${isRecurrentField ? "10rem" : ""} 5rem ${
            colorField ? "5rem" : ""
          } ${canDeactivate ? "5rem" : ""}`,
        }}
      >
        <div>
          <div className={isDeactivated ? "text-gray-300" : ""}>
            {resource[fieldTitle]}
          </div>
          {fieldSubtitle && resource[fieldSubtitle] && (
            <div
              className={
                isDeactivated
                  ? "text-gray-300"
                  : "text-gray-600 text-opacity-75"
              }
            >
              {resource[fieldSubtitle]}
            </div>
          )}
        </div>
        {isRecurrentField && (
          <div>
            {resource.isRecurrent ? (
              <button
                disabled
                className={`w-8 h-8 rounded-full flex justify-center items-center bg-green-600 focus:outline-none`}
                style={{ cursor: "default" }}
              >
                <RecurrentIcon className="w-4 h-4 fill-current text-white" />
              </button>
            ) : (
              <button
                disabled
                className={`w-8 h-8 rounded-full flex justify-center items-center bg-red-600 focus:outline-none`}
                style={{ cursor: "default" }}
              >
                <NotRecurrentIcon className="w-4 h-4 fill-current text-white" />
              </button>
            )}
          </div>
        )}
        {canDeactivate && (
          <div>
            <Toggle
              name="active"
              value={get(resource, "active", false)}
              checked={get(resource, "active", false)}
              submitting={isActivating}
              onChange={async () => {
                setIsActivating(true);
                await updateResource({
                  id: resource.id,
                  data: { active: !get(resource, "active", false) },
                });
                setIsActivating(false);
                toast.success("Mise à jour effectuée avec succès");
              }}
            />
          </div>
        )}
        {colorField && resource[colorField] && (
          <div className="flex justify-center items-center">
            <div
              className="h-6 w-6"
              style={{ backgroundColor: resource[colorField] }}
            />
          </div>
        )}
        <div
          className={`flex ${
            canDelete && deleteHook ? "justify-between" : "justify-center"
          } items-center`}
        >
          <button
              disabled={isDeactivated}
              className={`w-8 h-8 rounded-full flex justify-center items-center ${
                  isDeactivated ? "bg-gray-300 cursor-not-allowed" : "bg-orange-250"
              } focus:outline-none`}
              onClick={() => {
                setModalEditOpen(true);
              }}
          >
            <PenIcon className="w-4 h-4 fill-current text-white" />
          </button>
          {canDelete && deleteHook && (
            <DeleteButton
              isDeactivated={isDeactivated}
              deleteHook={deleteHook}
              resourceId={resource.id}
              setLastError={setLastError}
            />
          )}
        </div>
      </div>
      <Modal
        title={`Modifier`}
        handleClose={(e) => {
          e.stopPropagation();
        }}
        isOpen={modalEditOpen}
        onRequestClose={() => setModalEditOpen(false)}
      >
        {imageUpload && (
            <UploadAvatar domainLinkId={resourceId} avatar={avatar} />
        )}
        <div className="bg-white">
          {SpecificForm ? (
            <SpecificForm
              entity={resource}
              onCancel={() => setModalEditOpen(false)}
              onSubmit={async (values) => {
                if (readOnly) {
                  return;
                }
                await updateResource(values);
                setModalEditOpen(false);
                toast.success("Mise à jour effectuée avec succès");
              }}
            />
          ) : (
            <EditResource
              initialValues={mapInitialValues(
                mapResourceToFields(resource, fieldsConfig)
              )}
              validation={validation}
              fieldsConfig={mapIsEditing(fieldsConfig, !readOnly)}
              onCancel={() => setModalEditOpen(false)}
              onSubmit={async (values) => {
                if (readOnly) {
                  return;
                }
                await updateResource({
                  id: resource.id,
                  data: SpecificMapFormValues
                    ? SpecificMapFormValues(values)
                    : values,
                });
                toast.success("Mise à jour effectuée avec succès");
              }}
              onSubmitEnd={async () => {
                setModalEditOpen(false);
              }}
            />
          )}

          {lastError ? (
            <Alert
              className="mt-4"
              type={lastError.type || "error"}
              message={lastError.title || "Erreur"}
              details={lastError.description}
            />
          ) : null}
        </div>
      </Modal>
    </>
  );
}

function ManageResource({
  resourceLabel,
  noElementsLabel,
  createLabel,
  createHook,
  searchLabel = "Rechercher",
  CreateForm,
  queryHook,
  canDeactivate = false,
  searchField = null,
  params: fieldsConfig,
  fieldTitle = "label",
  fieldSubtitle = null,
  colorField = null,
  isRecurrentField = null,
  updateHook,
  deleteHook,
  canDelete = true,
  toastMessage = null,
  validation = Yup.object(),
  denominationLabel,
  buttons,
  readOnly = false,
  SpecificForm,
  SpecificMapFormValues = false,
  imageUpload = false,
  draggable = false,
  childrenTop = null,
}) {
  if (!createHook || !queryHook || !updateHook) {
    throw new Error(
      "createHook, queryHook, updateHook and deleteHook are mandatory"
    );
  }

  const [createResource, { error: errorCreate }] = createHook();

  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [,] = useDebounce(
    () => {
      setDebouncedQuery(query);
    },
    500,
    [query]
  );

  const { data, error } = queryHook(
    searchField ? { [searchField]: debouncedQuery } : {}
  );

  const [items, setItems] = React.useState()
  React.useEffect(() => {
    setItems(data);
  }, [data, setItems]);
  const [isLoading, setIsLoading] = React.useState(false)

  const [updateResource] = updateHook();
  const onDragEnd = (results) => {
    return new Promise((resolve, reject) => {
      const newItems = arrayMove(items, results.source.index, results.destination.index);
      resolve(newItems);
    });
  }

  if (error) {
    return (
      <Alert
        type="error"
        message={get(error, "title", "Erreur")}
        details={get(
          error,
          "description",
          `Récupération des ${resourceLabel} impossible`
        )}
      />
    );
  }

  return (
    <>
      <div>
        <Helmet>
          <title>Administration {resourceLabel}</title>
        </Helmet>

        <HeaderBar
          title={`Administration ${resourceLabel}`}
          buttons={
            <button
              type="button"
              className="absolute bg-green-600 hover:bg-green-800 text-white rounded-full w-12 h-12 -mb-5 mr-5 bottom-0 right-0 flex justify-center items-center focus:outline-none"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <PlusIcon className="w-4 h-4" />
            </button>
          }
        >
          {!!searchField ? (
            <div>
              <input
                type="text"
                name="query"
                value={query}
                className="mb-0 appearance-none bg-transparent border-b border-white w-full py-2  leading-tight focus:outline-none focus:border-red"
                placeholder={searchLabel}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          ) : null}
        </HeaderBar>

        {childrenTop}

        <div className={`px-8 mb-48 mt-16 flex justify-center`}>
          <div className="md:w-2/3 w-full">
            <React.Suspense fallback={<Spinner />}>
              <div
                className="grid gap-4 items-center py-4 text-lg text-gray-600 text-opacity-75 uppercase"
                style={{
                  gridTemplateColumns: `auto ${
                    isRecurrentField ? "12rem" : ""
                  } 5rem ${colorField ? "5rem" : ""} ${
                    canDeactivate ? "5rem" : ""
                  }`,
                }}
              >
                <div>{denominationLabel || "Dénomination"}</div>
                {canDeactivate && <div>Statut</div>}
                {colorField && <div>Couleur</div>}
                {isRecurrentField && <div>Recurrent</div>}
                <div>Actions</div>
              </div>
              {isEmpty(data) && (
                <Alert details={noElementsLabel || `Aucun élément`} />
              )}
              {isLoading ? (
                  <div className="loader h-8 w-8 mx-auto"></div>
              ) : draggable ? (
                  <DragDropContext
                      onDragEnd={(result) => {
                        onDragEnd(result).then(async (newItems) => {
                          setIsLoading(true);
                          for (const [index, {id}] of valuesL(newItems).entries()) {
                            await updateResource({
                              id: id,
                              data: {position: index}
                            })
                          }
                          setItems(newItems);
                          setIsLoading(false);
                        });
                      }}
                  >
                    <Droppable key={1} droppableId='droppable'>
                      {(provided, snapshot) => {
                        return (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                              {map(items, (resource, index) => {
                                return (
                                    <Draggable
                                        key={resource.id}
                                        draggableId={`draggable-${resource.id}`}
                                        index={index}
                                    >
                                      {(provided, snapshot) => {
                                        return (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{
                                                  ...provided.draggableProps.style,
                                                }}
                                            >
                                              <Resource
                                                  key={resource.id}
                                                  resource={resource}
                                                  fieldTitle={fieldTitle}
                                                  colorField={colorField}
                                                  isRecurrentField={isRecurrentField}
                                                  fieldSubtitle={fieldSubtitle}
                                                  fieldsConfig={fieldsConfig}
                                                  updateHook={updateHook}
                                                  deleteHook={deleteHook}
                                                  canDeactivate={canDeactivate}
                                                  canDelete={canDelete}
                                                  buttons={buttons}
                                                  readOnly={readOnly}
                                                  SpecificForm={SpecificForm}
                                                  validation={validation}
                                                  SpecificMapFormValues={SpecificMapFormValues}
                                                  imageUpload={imageUpload}
                                              />
                                            </div>
                                        )}}
                                    </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                        )}}
                    </Droppable>
                  </DragDropContext>
                  ) : (
                      <>
                        {map(data, (resource) => {
                          return (
                              <Resource
                              key={resource.id}
                              resource={resource}
                              fieldTitle={fieldTitle}
                              colorField={colorField}
                              isRecurrentField={isRecurrentField}
                              fieldSubtitle={fieldSubtitle}
                              fieldsConfig={fieldsConfig}
                              updateHook={updateHook}
                              deleteHook={deleteHook}
                              canDeactivate={canDeactivate}
                              canDelete={canDelete}
                              buttons={buttons}
                              readOnly={readOnly}
                              SpecificForm={SpecificForm}
                              validation={validation}
                              SpecificMapFormValues={SpecificMapFormValues}
                              imageUpload={imageUpload}
                              />
                          );
                        })}
                      </>
              )}
            </React.Suspense>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        title={createLabel || "Créer un nouvel élément"}
      >
        {CreateForm ? (
          <CreateForm />
        ) : SpecificForm ? (
          <SpecificForm
            onSubmit={async (values) => {
              await createResource(values);
              setIsOpen(false);
              if (toastMessage) {
                toast.success(toastMessage);
              }
            }}
          />
        ) : (
          <Formik
            initialValues={mapInitialValues(fieldsConfig)}
            validationSchema={validation}
            onSubmit={async (values, actions) => {
              try {
                SpecificMapFormValues
                  ? await createResource(SpecificMapFormValues(values))
                  : await createResource(mapFormValues(values));
                if (toastMessage) {
                  toast.success(toastMessage);
                }
                actions.resetForm();
              } catch (error) {
                setIsOpen(false);
                map(get(error, "violations"), (e) => {
                  actions.setFieldError(e.propertyPath, e.message);
                });
              } finally {
                actions.setSubmitting(false);
                setIsOpen(false);
              }
            }}
          >
            {({ isSubmitting, values }) => {
              return (
                <Form>
                  {mapInputs({ fields: fieldsConfig, values: values })}

                  {errorCreate ? (
                    <Alert
                      canBeHidden
                      type="error"
                      message={errorCreate.title}
                      details={errorCreate.description}
                    />
                  ) : null}

                  <div className="text-center">
                    <Button
                      className={`btn mt-6 inline-block`}
                      isSubmitting={isSubmitting}
                      isForm={true}
                      type="submit"
                      textLabel="Créer"
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </Modal>
    </>
  );
}

export default ManageResource;
