import React from "react";
import { Form, Formik } from "formik";
import { get, map, noop, reduce } from "lodash-es";
import { toast } from "react-toastify";
import Alert from "../components/Alert";
import Button from "../components/Button";
import { useUpdateCollaborator } from "../api/collaborator";
import { getFirstnameLastnameJob } from "../utils/names";
import { DisplaySelect } from "../components/Select";
import * as Yup from "yup";
import { ReactComponent as FilterIcon } from "../svgs/filters.svg";
import { useMap, useToggle } from "react-use";
import Block from "../components/Block";
import { ReactComponent as CrossIcon } from "../svgs/cross.svg";
import SelectCollaborator from "../components/SelectCollaborator";
import { useQueryEvaluatorById } from "../api/evaluator";
import { useQueryJobs } from "../api/jobs";
import { useQueryAgencies } from "../api/agencies";
import { useQueryDepartments } from "../api/department";
import { useQueryServices } from "../api/services";

const transformSelect = (collaborator, isIri = true) => {
  return {
    value: get(collaborator, isIri ? "@id" : "id"),
    label: getFirstnameLastnameJob({ collaborator }, "collaborator"),
  };
};

const schema = Yup.object().shape({
  evaluator: Yup.object().shape({
    label: Yup.string().required("Ce champ est requis"),
  }),
  collaboratorsCanBeEvaluate: Yup.array()
    .of(Yup.object())
    .min(1, "Ce champ est requis"),
});

export function Filters({
  AvailableFilters,
  toggleClose,
  currentFilter,
  setFilter,
  resetFilters,
  isModal,
}) {
  return (
    <Block>
      <div onClick={toggleClose} className={"ml-auto cursor-pointer w-4"}>
        <CrossIcon width={"27px"} height={"27px"} />
      </div>
      <div
        className={`flex ${
          isModal ? "flex-col" : "flex-wrap"
        } px-4 justify-around gap-10`}
      >
        {map(AvailableFilters, (filter) => {
          return (
            <DisplaySelect
              isMulti
              key={filter.key}
              label={filter.label}
              name={filter.key}
              options={filter.options}
              value={get(currentFilter, filter.key, [])}
              onChange={(e) => {
                setFilter(filter.key, e);
              }}
              className={"flex-grow min-w-200p"}
            />
          );
        })}
      </div>

      <div>
        <div
          className="mt-3 underline cursor-pointer"
          onClick={() => {
            resetFilters();
          }}
        >
          Supprimer les filtres
        </div>
      </div>
    </Block>
  );
}

export default function EvaluatorForm({
  evaluator = null,
  readOnly = false,
  onSuccess = noop,
  isModal = false,
  isOpen,
}) {
  const { data: collaborator } = useQueryEvaluatorById(isOpen && evaluator.id);
  const initialCanBeValidate = get(
    collaborator,
    "collaboratorsCanBeEvaluate",
    [],
  );

  const queryFilters = {
    "exists[linkedUser]": true,
    "exists[collaboratorsCanBeEvaluate]": false,
  };
  const [
    filters,
    { set: setFilter, remove: removeFilter, reset: resetFilters },
  ] = useMap({});
  const [filterOn, filterToggle] = useToggle(false);
  const { data: jobs } = useQueryJobs();
  const { data: agencies } = useQueryAgencies();
  const { data: departments } = useQueryDepartments();
  const { data: services } = useQueryServices();

  //Ajout des filtres
  const formatedFilters = {};

  for (const key in filters) {
    // check also if property is not inherited from prototype
    if (filters.hasOwnProperty(key)) {
      formatedFilters[key] = reduce(
        filters[key],
        (result, f) => {
          result.push(f.value);
          return result;
        },
        [],
      );
    }
  }

  const filtersList = [
    {
      label: "Site",
      key: "agencies",
      options: map(agencies, (node) => ({
        label: node.label,
        value: node["@id"],
        id: node["@id"],
      })),
    },
    {
      label: "Départements",
      key: "departments",
      options: map(departments, (node) => ({
        label: node.label,
        value: node["@id"],
        id: node["@id"],
      })),
    },
    {
      label: "Service",
      key: "service",
      options: map(services, (node) => ({
        label: node.code,
        value: node["@id"],
        id: node["@id"],
      })),
    },
    {
      label: "Emploi",
      key: "currentContract.job",
      options: map(jobs, (node) => ({
        label: node.label,
        value: node["@id"],
        id: node["@id"],
      })),
    },
  ];

  const [updateEvaluator, { error, status }] = useUpdateCollaborator();
  return (
    <div>
      <button
        className={`${
          !readOnly ? "flex" : "hidden"
        } items-center focus:outline-none mb-4`}
        onClick={filterToggle}
      >
        <FilterIcon />
        <span className="ml-4">Filtres</span>
      </button>
      <div className={`${!readOnly && filterOn ? "block" : "hidden"}`}>
        <Filters
          AvailableFilters={filtersList}
          setFilter={setFilter}
          removeFilter={removeFilter}
          resetFilters={resetFilters}
          currentFilter={filters}
          toggleClose={filterToggle}
          isModal={isModal}
        />
      </div>
      <Formik
        enableReinitialize
        validationSchema={schema}
        initialValues={{
          evaluator: {
            value: get(evaluator, "id", ""),
            label: evaluator ? getFirstnameLastnameJob(evaluator) : "",
          },
          collaboratorsCanBeEvaluate: map(initialCanBeValidate, (v) => {
            //Si on peut se valider soit même, le collab n'est pas sérialisé à ce niveau,
            // on récupère les infos au niveau évaluateur
            if (typeof v === "string") {
              if (v === get(evaluator, "@id", "")) {
                return {
                  value: get(evaluator, "@id", ""),
                  label: evaluator ? getFirstnameLastnameJob(evaluator) : "",
                };
              }
            } else {
              return {
                value: v["@id"],
                label: getFirstnameLastnameJob(v),
              };
            }
          }),
        }}
        onSubmit={async (values, actions) => {
          try {
            //Si on a déjà un évaluateur et qu'il a été modifié on supprime les valeurs de l'ancien
            if (
              evaluator &&
              get(evaluator, "id", "") !== values.evaluator.value
            ) {
              await updateEvaluator({
                id: get(evaluator, "id", ""),
                data: { collaboratorsCanBeEvaluate: [] },
              });
            }
            await updateEvaluator({
              id: values.evaluator.value,
              data: {
                collaboratorsCanBeEvaluate: map(
                  values.collaboratorsCanBeEvaluate,
                  "value",
                ),
              },
            });
            toast.success("Mise à jour effectuée avec succès");
            onSuccess();
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          }
          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting, values }) => {
          return (
            <Form>
              
              <SelectCollaborator
                name="evaluator"
                queryFilters={queryFilters}
                label={"Évaluateur"}
                value={values.evaluator}
                readOnly={readOnly}
                valueId
                addOptions={
                  evaluator ? [transformSelect(evaluator, false)] : []
                }
              />
              <SelectCollaborator
                name="collaboratorsCanBeEvaluate"
                label="Collaborateurs évalués"
                value={values.collaboratorsCanBeEvaluate}
                isMulti
                displayConcat={!evaluator}
                readOnly={readOnly}
                allowSelectAll
                queryFilters={formatedFilters}
              />
              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}

              <div className="flex items-center gap-3 mb-3">
                <Button
                  className={`btn btn--sm`}
                  readOnly={readOnly}
                  isSubmitting={status === "loading" || isSubmitting}
                  isForm={true}
                  type="submit"
                  textLabel={evaluator ? "Enregistrer" : "Créer"}
                />
                {evaluator && (
                  <Button
                    type="button"
                    className={`btn btn--error btn--sm`}
                    readOnly={readOnly}
                    isSubmitting={status === "loading" || isSubmitting}
                    onClick={async () => {
                      const res = window.confirm(
                        "La suppression sera définitive",
                      );
                      if (res) {
                        await updateEvaluator({
                          id: values.evaluator.value,
                          data: { collaboratorsCanBeEvaluate: [] },
                        });
                      }
                    }}
                  >
                    Supprimer
                  </Button>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
