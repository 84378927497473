import { get, uniqBy } from "lodash-es";
import {
  useAsyncFn,
  useDeepCompareEffect,
  useMountedState,
  useSetState,
} from "react-use";

import axios from "axios";
import { logout } from "./graphql";
import { useEffect } from "react";
import jwtDecode from "jwt-decode";

const makeRequestCreator = () => {
  let call;
  return (url, options) => {
    if (call) {
      call.cancel("");
    }
    call = axios.CancelToken.source();
    return axios(url, {
      cancelToken: call.token,
      ...options,
    });
  };
};

const cancellableRequest = makeRequestCreator();

export async function requestWrapper(url, options = {}, headers = {}) {
  if (!url) {
    throw new Error("Url is mandatory");
  }
  const fetchFn = options.cancellable ? cancellableRequest : axios;

  try {
    const token = window.localStorage.getItem("token");
    const response = await fetchFn(url, {
      ...options,
      headers: {
        Authorization: `bearer ${token}`,
        "content-type": "application/ld+json",
        ...headers,
      },
    });

    return response.data;
  } catch (error) {
    if (
      get(error, "response.data.message") === "Expired JWT Token" ||
      get(error, "response.data.message") === "Invalid JWT Token"
    ) {
      logout();
      return;
    }
    if (axios.isCancel(error)) {
      console.error(`Cancelling previous request: ${error.message}`);
    }
    throw error.response || error.message;
  }
}

export function useCollection({ url, cancellable = false, params = {} }) {
  const initialState = {
    page: 1,
    total: 0,
    hasNext: true,
    data: [],
    hasLoaded: false,
  };
  const isMounted = useMountedState();
  const [state, setState] = useSetState(initialState);

  const [asyncState, fetch] = useAsyncFn(async () => {
    try {
      if (isMounted()) {
        const response = await requestWrapper(url, {
          cancellable: cancellable,
          method: "GET",
          params: {
            page: state.page,
            ...params,
          },
        });

        setState({
          page: state.page + 1,
          total: get(response, "hydra:totalItems", 0),
          hasNext: get(response, "hydra:view.hydra:next") ? true : false,
          data: uniqBy(
            [...state.data, ...get(response, "hydra:member", [])],
            "id",
          ),
          hasLoaded: true,
        });

        return response;
      }
    } catch (error) {
      setState({ hasLoaded: true });
      throw error;
    }
  }, [state, isMounted]);

  useDeepCompareEffect(() => {
    if (!asyncState.loading) {
      setState(initialState);
    }
  }, [params]);

  useEffect(() => {
    if (!asyncState.loading && !state.hasLoaded) {
      fetch();
    }
  }, [fetch, asyncState, state]);

  return {
    loading: asyncState.loading || false,
    error: asyncState.error,
    ...state,
    reset: () => {
      if (!asyncState.loading) {
        setState(initialState);
      }
    },
    fetchNextPage: () => {
      if (!asyncState.loading && state.hasNext) {
        fetch();
      }
    },
  };
}

export async function login(data = {}) {
  try {
    const { token, actions, modules, refreshToken } = await requestWrapper(
      `${process.env.REACT_APP_API_URL}/login`,
      {
        method: "POST",
        data,
      },
    );
    return { token, actions, modules, refreshToken };
  } catch (error) {
    throw error;
  }
}
export async function refreshToken() {
  try {
    const { token } = await requestWrapper(
      `${process.env.REACT_APP_API_URL}/refresh`,
      {
        method: "POST",
      },
    );

    return token;
  } catch (error) {
    throw error;
  }
}

export function isTokenValid(token) {
  try {
    const decodedToken = jwtDecode(token);
    if (!decodedToken) {
      return false;
    }
    return Date.now() < decodedToken?.exp * 1000; // convert expiry timestamp from seconds to milliseconds
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function refreshTokenExpiration(refreshToken) {
  try {
    const { token, refreshToken: newRefreshToken } = await requestWrapper(
      `${process.env.REACT_APP_API_URL}/refresh/expiration`,
      {
        method: "POST",
        data: {
          refresh_token: refreshToken,
        },
      },
    );
    return { token, refreshToken: newRefreshToken };
  } catch (error) {
    throw error;
  }
}

// REQUEST

export function useCreateRequest() {
  const [state, fetch] = useAsyncFn(async (data = {}, onError = () => {}) => {
    try {
      const response = await requestWrapper(
        `${process.env.REACT_APP_API_URL}/requests`,
        {
          method: "POST",
          data,
        },
      );
      return response;
    } catch (error) {
      onError(error);
      throw error;
    }
  }, []);

  return [state, fetch];
}

// FAMILY SITUATION

export function useCreateOrUpdateFamilySituation() {
  const [state, fetch] = useAsyncFn(
    async ({ id, data = {}, onError = () => {} } = {}) => {
      try {
        const response = await requestWrapper(
          `${process.env.REACT_APP_API_URL}/family_situations${
            id ? `/${id}` : ""
          }`,
          {
            method: `${id ? "PATCH" : "POST"}`,
            data,
          },
        );
        return response;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    [],
  );

  return [state, fetch];
}

// COUNTRIES
export const useGetCountries = (params) =>
  useCollection({
    url: `${process.env.REACT_APP_API_URL}/countries`,
    cancellable: true,
    params,
  });

// MOODS TYPES
export const useGetMoods = (params) =>
  useCollection({
    url: `${process.env.REACT_APP_API_URL}/mood_types`,
    params,
  });

export const useCheckMood = (userId) => {
  const [state, fetch] = useAsyncFn(async ({ onError = () => {} } = {}) => {
    try {
      const response = await requestWrapper(
        `${process.env.REACT_APP_API_URL}/askmood/${userId}`,
        {
          method: `GET`,
        },
      );
      return response;
    } catch (error) {
      onError(error);
      throw error;
    }
  }, []);

  return [state, fetch];
};

export const useSendMood = () => {
  const [state, fetch] = useAsyncFn(
    async ({ data = {}, onError = () => {} } = {}) => {
      try {
        const response = await requestWrapper(
          `${process.env.REACT_APP_API_URL}/moods`,
          {
            method: `POST`,
            data,
          },
        );
        return response;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    [],
  );

  return [state, fetch];
};

// PERMISSIONS TYPES

// ACTIONS

export function useGetActions() {
  return useCollection({
    url: `${process.env.REACT_APP_API_URL}/actions?pagination=false`,
  });
}

// AVATAR

export function updateAvatar(file, collaboratorId, domainLinkId) {
  const data = new FormData();
  data.append("file", file);

  if (collaboratorId) {
    data.append("collaboratorId", collaboratorId);
  }
  if (domainLinkId) {
    data.append("domainLinkId", domainLinkId);
  }

  return requestWrapper(
    `${process.env.REACT_APP_API_URL}/avatars`,
    {
      method: "POST",
      data,
    },
    {
      "content-type": "multipart/form-data",
    },
  );
}
